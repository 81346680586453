import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { Box, Tooltip, Typography } from "@material-ui/core";
import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { GuestsSelection } from "redmond";

import { config } from "../../../../../../api/config";
import {
  CORP_MAXIMUM_ROOMS_COUNT,
  CORP_MAXIMUM_TRAVELERS_COUNT,
  MAXIMUM_ROOMS_COUNT,
  MAXIMUM_TRAVELERS_COUNT,
  STAYS_MAXIMUM_ROOMS_COUNT,
  STAYS_MAXIMUM_TRAVELERS_COUNT,
} from "../../../../textConstants";
import { OccupancySelectionConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  AVAILABLE,
  STAYS_SEARCH,
  getExperimentVariant,
  useExperiments,
} from "../../../../../../context/experiments";

export interface IOccupancySelectionProps
  extends OccupancySelectionConnectorProps,
    RouteComponentProps {
  showTotalTravelers?: boolean;
  showPets?: boolean;
}
export const OccupancySelection = (props: IOccupancySelectionProps) => {
  const {
    adultsCount,
    children,
    roomsCount,
    petsCount,
    setOccupancyCounts,
    setRoomsCount,
    setPetsCount,
    showTotalTravelers,
    showPets = false,
  } = props;

  const isMultiroomAmadeusEnabled =
    isCorpTenant(config.TENANT) &&
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const expState = useExperiments();
  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);

  const maximumRoomsCount = isMultiroomAmadeusEnabled
    ? CORP_MAXIMUM_ROOMS_COUNT
    : staysSearchEnabled
    ? STAYS_MAXIMUM_ROOMS_COUNT
    : MAXIMUM_ROOMS_COUNT;

  const maximumTravelersCount = isMultiroomAmadeusEnabled
    ? CORP_MAXIMUM_TRAVELERS_COUNT
    : staysSearchEnabled
    ? STAYS_MAXIMUM_TRAVELERS_COUNT
    : MAXIMUM_TRAVELERS_COUNT;

  return (
    <Box className="desktop-hotel-occupancy-selection">
      <B2BButton
        aria-label={`Guests ${textConstants.INPUT_LABEL(
          adultsCount,
          children
        )}`}
        className="num-travelers-input b2b"
        variant="traveler-selector"
        onClick={() => setOpenPassengerCountPicker(true)}
      >
        <Box className="num-traveler-content">
          <Box className="num-traveler-with-icon">
            <Icon
              aria-hidden={true}
              className="icon-start"
              name={IconName.B2BUser}
              ariaLabel=""
            />
            <Box
              className="text"
              dangerouslySetInnerHTML={{
                __html: textConstants.INPUT_LABEL_WITH_ROOMS(
                  adultsCount,
                  children,
                  roomsCount,
                  showTotalTravelers
                ),
              }}
            />
          </Box>
          <Icon
            aria-hidden={true}
            className="icon-end"
            name={IconName.B2BEditPencil}
            ariaLabel=""
          />
        </Box>
      </B2BButton>
      {openPassengerCountPicker && (
        <DesktopPopupModal
          open={openPassengerCountPicker}
          aria-labelledby="passenger-count-picker"
          className="desktop-passenger-count-picker-popup"
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.MODAL_TITLES.modalTitle}
        >
          <PassengerCountPicker
            titles={{
              ...textConstants.MODAL_TITLES_WITH_ROOMS(
                config.TENANT,
                maximumRoomsCount
              ),
              ...(showPets && {
                petsTitle: textConstants.PETS_TITLE,
                petsSubtitle: (
                  <>
                    <Tooltip
                      placement="bottom"
                      title={
                        <Typography
                          variant="subtitle2"
                          className="tooltip-text"
                          dangerouslySetInnerHTML={{
                            __html: textConstants.PETS_TOOLTIP,
                          }}
                        />
                      }
                      classes={{
                        popper: "pets-info-tooltip-popper",
                        tooltip: "pets-info-tooltip-text",
                      }}
                      enterTouchDelay={50}
                      leaveTouchDelay={2500}
                      tabIndex={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#676868",
                            lineHeight: "22px",
                          }}
                        >
                          {textConstants.PETS_SUBTITLE}
                        </Typography>
                        <Icon
                          className="icon-grey"
                          name={IconName.InfoCircle}
                          ariaLabel="More information about pets tooltip"
                        />
                      </div>
                    </Tooltip>
                  </>
                ),
              }),
            }}
            setPassengerCounts={(counts: PassengerCountPickerType) => {
              const { adults, children, rooms, pets } =
                counts as GuestsSelection;
              setOccupancyCounts({ adults, children });
              setRoomsCount(rooms);
              setPetsCount(pets ? pets : 0);
            }}
            onClickApply={() => setOpenPassengerCountPicker(false)}
            counts={{
              adults: adultsCount,
              children: children,
              rooms: roomsCount,
              pets: petsCount,
            }}
            className="b2b"
            showChildAgeInputs
            maximumRoomsCount={maximumRoomsCount}
            maximumTravelersCount={maximumTravelersCount}
            includeChildrenInMaxCount={staysSearchEnabled}
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};
