import { isCorpTenant } from "@capone/common";
import {
  CustomerAccountRole,
  RewardsAccount,
  HotelBookType,
  Tenant,
} from "redmond";

// TODO: relocate Cap One specific language to McLean; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
export const PAYMENT_STEP_TITLE = "Step 3: Rewards and Payment";
export const PAYMENT_STEP_2_TITLE = (canRedeemRewards: boolean) =>
  `Step 2: ${canRedeemRewards ? "Rewards and" : ""} Payment`;
export const PAYMENT_STEP_SUBTITLE = (
  hotelBookType: HotelBookType,
  canRedeemRewards: boolean
) => {
  if (canRedeemRewards) {
    return `You can use rewards and the payment method you add to ${
      hotelBookType === HotelBookType.PRICE_FREEZE_PURCHASE
        ? "freeze this price"
        : "book this trip"
    }.`;
  }
  return "Choose the payment method to book this trip.";
};
export const PAYMENT_METHOD_TITLE = "Payment Method";
// TODO: add remaining balance
export const PAYMENT_METHOD_SUBTITLE = `Add a Capital One credit card to pay your remaining balance | `;
export const PAYMENT_METHOD_SUBTITLE_AMOUNT = (amountDue: string) =>
  `Amount due: ${amountDue}`;
export const REWARDS_ACCOUNT_TITLE = "Select an account";
export const REWARDS_ACCOUNT_SUBTITLE =
  "Only the rewards from one of these accounts can be applied per transaction.";
export const UNABLED_TO_ADD_PAYMENT =
  "We are unable to add your payment method";
export const ADD_PAYMENT_AGAIN = "Please add your payment method again";
export const CAP_ONE_INVALID_CREDIT_CARD_TITLE =
  "It looks like you're trying to add an invalid credit card.";
export const CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE = (tenant: Tenant) =>
  `Ensure you are using ${
    isCorpTenant(tenant) ? "a Business Travel" : "an"
  } eligible Capital One credit card.`;
export const EDIT_PAYMENT_METHOD = "Edit Payment Method";
export const TRY_AGAIN = "Try Again";
export const CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT = "Add your";
export const CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT = "credit card";
export const ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS =
  "Add an eligible Capital One credit card";
export const ADD_PAYMENT_METHOD_MODAL_TITLE =
  "Add one of your Capital One credit cards";
export const ADD_ADDITIONAL_PAYMENT_METHOD_CTA = "Add another payment method";
export const BACK_TO_CARD_SELECTION_CTA = "Back to card selection";
export const CARD_ENDING_IN_TEXT = "Ending in";
export const ADD_PAYMENT_FORM_HEADER_TEXT = (cardName: string) =>
  `Add your <b>${cardName}</b> credit card`;
export const ADD_PAYMENT_FORM_SUBTITLE_TEXT = `You can choose to add your <b>physical card number</b> or <b>your virtual card number</b> if you’ve been issued one.`;
export const MOBILE_PAYMENT_STEP_TITLE = (canRedeemRewards: boolean) =>
  canRedeemRewards ? "Rewards and Payment" : "Payment";
export const MOBILE_PAYMENT_STEP_SUBTITLE = (
  hotelBookType: HotelBookType,
  canRedeemRewards: boolean
) => {
  if (canRedeemRewards) {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_PURCHASE:
        return "You can use rewards and the payment method to freeze this price.";
      case HotelBookType.PRICE_FREEZE_EXERCISE:
      case HotelBookType.DEFAULT:
      default:
        return "Pay for your booking using rewards, on a Capital One credit card, or use a combination of both.";
    }
  }
  return "Pay for your booking using a Capital One credit card.";
};
// generate a string like "Quicksilver, Venture, and Spark Cash"
export const REWARDS_ACCOUNT_NAMES = (rewardsAccounts: RewardsAccount[]) =>
  rewardsAccounts.reduce((accountNamesString, account, index) => {
    if (rewardsAccounts.length > 1) {
      if (index === rewardsAccounts.length - 1) {
        return `${accountNamesString}${account.productDisplayName}`;
      }
      if (index === rewardsAccounts.length - 2) {
        return `${accountNamesString}${account.productDisplayName}${
          rewardsAccounts.length > 2 ? "," : ""
        } and `;
      }
      return `${accountNamesString}${account.productDisplayName}, `;
    }
    return account.productDisplayName;
  }, "");
export const INELIGIBLE_ACCOUNTS_NOTICE = (
  rewardsAccounts: RewardsAccount[]
) => {
  const ineligibleAccounts = rewardsAccounts.filter(
    (account) => !(account.allowRewardsRedemption ?? true)
  );
  return ineligibleAccounts.length === rewardsAccounts.length
    ? "Please use your eligible credit card to pay for this booking."
    : `Your ${REWARDS_ACCOUNT_NAMES(ineligibleAccounts)} account${
        ineligibleAccounts.length > 1 ? "s are" : " is"
      } not eligible to be redeemed from.`;
};
export const ACCOUNT_ROLE_TEXT = (
  ineligibleRewardsAccounts: RewardsAccount[]
) => {
  const roleToUse = ineligibleRewardsAccounts[0]?.customerAccountRole;

  switch (roleToUse) {
    case CustomerAccountRole.AuthorizedUser:
    default:
      return "authorized user";
    case CustomerAccountRole.AccountManager:
      return "account manager";
  }
};
export const INELIGIBLE_ACCOUNTS_TOOLTIP = (
  ineligibleRewardsAccounts: RewardsAccount[]
) =>
  `Your ${REWARDS_ACCOUNT_NAMES(ineligibleRewardsAccounts)} rewards account${
    ineligibleRewardsAccounts.length > 1 ? "s are" : " is"
  } not eligible to be redeemed for travel because you are an ${ACCOUNT_ROLE_TEXT(
    ineligibleRewardsAccounts
  )}. Please use your eligible credit card to pay for this booking.`;

export const PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS = (
  hotelBookType: HotelBookType,
  canRedeemRewards: boolean,
  includeOffers: boolean
) => {
  if (canRedeemRewards) {
    return `Combine your travel credits,${
      includeOffers ? " a travel offer," : ""
    } rewards, and the payment method you add to ${
      hotelBookType === HotelBookType.PRICE_FREEZE_PURCHASE
        ? "freeze this price"
        : "book this trip"
    }.`;
  }
  return "Choose the payment method to book this trip.";
};

export const PAYMENT_METHOD_SUBTITLE_EARN_REWARDS = (productName: string) =>
  `You must use the ${productName} card that is associated with your Capital One Business Travel account to get enhanced earn on eligible hotel and car rental bookings.`;

export const PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT =
  "You can use any credit card that is associated with this Capital One Business Travel account: ";

export const CTA_CORP_NON_FINANCIAL_USER_CREDIT_CARD_TEXT = "Add credit card";

export const PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT_INFO = (
  productName: string,
  lastFour: string,
  businessName: string
) => `${productName} ${lastFour}, ${businessName}`;
