import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  BannerSeverity,
  DatePickerButton,
  Icon,
  IconName,
  MobileSearchFieldButton,
  NotificationBanner,
} from "halifax";
import H from "history";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { IIdLodgings, RecentHotelSearch } from "redmond";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { HotelSearchButton } from "../HotelSearchControl/components/SearchButton";
import { OccupancySelection } from "../MobileHotelSearchControl/components";
import { MobileCalendarPickerModal } from "./components/MobileCalendarPickerModal";
import { MobileLocationSearchModal } from "./components/MobileLocationSearchModal";
import { MobileHotelSearchV2ConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  AVAILABLE,
  STAYS_SEARCH,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IMobileHotelSearchControlProps
  extends MobileHotelSearchV2ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
}

export const MobileHotelSearchControlV2 = (
  props: IMobileHotelSearchControlProps
) => {
  const {
    value,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    history,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    React.useState(false);

  const isReadyToSearch = !!value && !!fromDate && !!untilDate && !!adultsCount;
  const expState = useExperiments();
  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  React.useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [value, fromDate, untilDate, adultsCount]);

  const handleSearch = (history: H.History) => {
    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (value?.id as IIdLodgings).lodgingSelection.searchTerm,
        fromDate,
        untilDate,
        adultsCount,
        children,
        roomsCount
      )}`
    );
  };

  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };
  return (
    <Box className="mobile-hotel-search-v2">
      <OccupancySelection
        showTotalTravelers
        autoFocus={false}
        showClosePassengerModalButton
        showPets={staysSearchEnabled}
      />
      <MobileSearchFieldButton
        value={value?.label}
        label={
          value?.label
            ? textConstants.WHERE_TO
            : textConstants.WHERE_ARE_YOU_STAYING
        }
        icon={<Icon name={IconName.B2BMapPin} />}
        renderPopup={() => (
          <MobileLocationSearchModal
            openLocationModal={openLocationModal}
            setOpenLocationModal={setOpenLocationModal}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
          />
        )}
        onClick={() => setOpenLocationModal(true)}
        isMissingSearchInfo={hasMissingSearchInfoError && !value}
      />

      {!fromDate || !untilDate ? (
        <MobileSearchFieldButton
          label={textConstants.CHOOSE_DATES}
          icon={<Icon name={IconName.Calendar} />}
          renderPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              onComplete={onSelectDates}
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          isMissingSearchInfo={
            hasMissingSearchInfoError && (!fromDate || !untilDate)
          }
        />
      ) : (
        <DatePickerButton
          startDate={fromDate}
          endDate={untilDate}
          startLabel={textConstants.CHECK_IN}
          endLabel={textConstants.CHECKOUT}
          classes={["hotel-inline-juncture-input"]}
          renderCalendarPopup={() => (
            <MobileCalendarPickerModal
              openCalendarModal={openCalendarModal}
              setOpenCalendarModal={setOpenCalendarModal}
              handleIncompleteSearch={setHasMissingSearchInfoError}
              onComplete={onSelectDates}
            />
          )}
          onClick={() => setOpenCalendarModal(true)}
          dateFormat={"ddd, MMM D"}
        />
      )}

      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      <HotelSearchButton
        className={clsx("mobile-hotel-search-control-button", "b2b")}
        message={textConstants.SEARCH_HOTELS}
        onClick={(history) => {
          handleSearchClick(history);
        }}
        enabled
      />
    </Box>
  );
};
