import React, { useContext, useState } from "react";
import { Box } from "@material-ui/core";
import {
  NoResults,
  ActionButton,
  useDeviceTypes,
  GenericInfoPopup,
  IconName,
  Icon,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
} from "halifax";
import clsx from "clsx";
import { CLEAR_ALL_FILTERS } from "redmond";
import { isCorpTenant } from "@capone/common";

import { AvailabilityNoResultsConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { PATH_HOME, PATH_HOME_STAYS } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import { MobileCalendarPicker } from "../../../search/components/MobileHotelSearchControl/components";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  addTrackingProperties,
  AVAILABLE,
  getExperimentVariant,
  STAYS_SEARCH,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";

export interface IAvailabilityNoResultsProps
  extends AvailabilityNoResultsConnectorProps {}

export const AvailabilityNoResults = (props: IAvailabilityNoResultsProps) => {
  const {
    resetFilters,
    history,
    unfilteredLodgingsEmpty,
    isFilteredHotelAvailabilityLodgingsEmpty,
    openDatesModal,
    setOpenDatesModal,
    datesModalOpen,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const { isAgentPortal, isAutoApprovalEnabled } = useContext(ClientContext);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const expState = useExperiments();

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const getEmptyResultsString = () => {
    if (matchesMobile && !isCorpTenant(config.TENANT)) {
      return textConstants.NO_HOTELS_FILTER_SUBTITLE_TEXT;
    }

    if (unfilteredLodgingsEmpty) {
      if (isAutoApprovalEnabled || !isCorpTenant(config.TENANT)) {
        return textConstants.NO_HOTELS_RETURNED_SUBTITLE_TEXT;
      } else {
        return textConstants.NO_HOTELS_24H_REVIEW_SUBTITLE_TEXT;
      }
    }

    return textConstants.NO_HOTELS_FILTER_SUBTITLE_TEXT;
  };

  const emptyResultsString = getEmptyResultsString();

  const primaryCTAHandler = unfilteredLodgingsEmpty
    ? openDatesModal
    : () => {
        resetFilters();
        trackEvent({
          eventName: CLEAR_ALL_FILTERS,
          properties: addTrackingProperties(expState.trackingProperties),
        });
      };
  const secondaryCTAHandler = () => {
    const path = staysSearchEnabled ? PATH_HOME_STAYS : PATH_HOME;
    unfilteredLodgingsEmpty ? history.push(path) : openDatesModal();
  };

  const buttons = [
    {
      buttonText: textConstants.RESET_BUTTON_TEXT,
      buttonWrapperClassName: clsx(
        "availability-no-results-buttons",
        "primary"
      ),
      onClick: () => {
        setIsOpen(false);
        resetFilters();

        trackEvent({
          eventName: CLEAR_ALL_FILTERS,
          properties: addTrackingProperties(expState.trackingProperties),
        });
      },
    },
    {
      buttonText: textConstants.ADJUST_DATES_BUTTON_TEXT,
      buttonWrapperClassName: clsx(
        "availability-no-results-buttons",
        "secondary"
      ),
      onClick: () => {
        setIsOpen(false);
        openDatesModal();
      },
    },
  ];

  return matchesMobile ? (
    <Box>
      <GenericInfoPopup
        buttons={buttons}
        className={clsx("availability-no-results-root")}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        isAgent={isAgentPortal}
        isMobile={matchesMobile}
        open={isOpen || isFilteredHotelAvailabilityLodgingsEmpty}
        subtitle={emptyResultsString}
        title={textConstants.NO_HOTELS_TITLE_TEXT}
      />
      <MobilePopoverCard
        open={datesModalOpen}
        onClose={() => setOpenDatesModal(false)}
        fullScreen={true}
        className="mobile-dates-selection-root"
        contentClassName="mobile-dates-selection-content-wrapper"
        topRightButton={
          <ActionLink
            className="dates-selection-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={() => setOpenDatesModal(false)}
          />
        }
      >
        <Box className="mobile-dates-selection-content-container">
          <MobileCalendarPicker
            onComplete={() => {
              setOpenDatesModal(false);
            }}
          />
        </Box>
      </MobilePopoverCard>
    </Box>
  ) : (
    <Box
      className={clsx("availability-no-results-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className={clsx("availability-no-results-container", config.TENANT)}>
        <NoResults
          className={clsx("availability-no-results", {
            "approval-required": !isAutoApprovalEnabled,
          })}
          title={textConstants.NO_HOTELS_TITLE_TEXT}
          subtitle={emptyResultsString}
        />
        <Box className="availability-no-results-buttons">
          <ActionButton
            className="search-again-button"
            defaultStyle="h4r-primary"
            message={
              unfilteredLodgingsEmpty
                ? textConstants.ADJUST_DATES_BUTTON_TEXT
                : textConstants.RESET_BUTTON_TEXT
            }
            onClick={primaryCTAHandler}
          />
          <ActionButton
            className="reset-button"
            defaultStyle="h4r-secondary"
            message={
              unfilteredLodgingsEmpty
                ? textConstants.SEARCH_AGAIN_BUTTON_TEXT
                : textConstants.ADJUST_DATES_BUTTON_TEXT
            }
            onClick={() => secondaryCTAHandler()}
          />
        </Box>
      </Box>
    </Box>
  );
};
