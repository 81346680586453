import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityFilter } from "./component";
import {
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityMinMaxPriceRange,
  getHotelAvailabilityCurrency,
  getHotelAvailabilityHotelNameFilter,
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityStayTypeFilter,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilitySortOption,
  getHotelAvailabilityHotelsOnSaleFilter,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getUserHotelPreferencesCallState,
  getHasUserSetHotelPreferences,
  getUserHotelPreferences,
  getAppliedHotelAvailabilityFilterCount,
  getShowPremiumStaysOnlyFilter,
  getPremiumStaysLodgingsCount,
  hasChangedHotelAvailabilityPolicyFilter,
  getHotelAvailabilityFiltersBannerMessage,
  getSearchLocationLabel,
  getHotelAvailabilityCallState,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityStayTypeFilter,
  getFirstIndexOfHomesInAvailabilityLodgingsResults,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
} from "../../reducer";
import {
  setAmenitiesFilter,
  setStarRatingsFilter,
  setMaxPriceFilter,
  setHotelNameFilter,
  setFreeCancelFilter,
  setHotelAvailabilitySortOption,
  setHotelsOnSaleFilter,
  setPolicyFilter,
  setLoyaltyProgramsFilter,
  setPremiumStaysOnly,
  setStayTypeFilter,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getApplyUserHotelPreferences } from "../../../search/reducer";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    locationLabel: getSearchLocationLabel(state),
    amenities: getHotelAvailabilityAmenitiesFilter(state),
    bannerMessage: getHotelAvailabilityFiltersBannerMessage(state),
    starRatings: getHotelAvailabilityStarRatingsFilter(state),
    maxPrice: getHotelAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    stayTypes: getHotelAvailabilityStayTypeFilter(state),
    currency: getHotelAvailabilityCurrency(state),
    hotelName: getHotelAvailabilityHotelNameFilter(state),
    hasChangedAmenitiesFilter:
      hasChangedHotelAvailabilityAmenitiesFilter(state),
    hasChangedStarRatingsFilter:
      hasChangedHotelAvailabilityStarRatingsFilter(state),
    hasChangedMaxPriceFilter: hasChangedHotelAvailabilityMaxPriceFilter(state),
    hasFreeCancelFilter: getHotelAvailabilityFreeCancelFilter(state),
    hasHotelsOnSaleFilter: getHotelAvailabilityHotelsOnSaleFilter(state),
    sortOption: getHotelAvailabilitySortOption(state),
    isInPolicy: getHotelAvailabilityPolicyFilter(state),
    loyaltyPrograms: getHotelAvailabilityLoyaltyProgramsFilter(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHotelPreferences: getUserHotelPreferences(state),
    userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
    hasUserSetHotelPreferences: getHasUserSetHotelPreferences(state),
    appliedFilterCount: getAppliedHotelAvailabilityFilterCount(state),
    premiumStaysOnly: getShowPremiumStaysOnlyFilter(state),
    premiumStaysLodgingCount: getPremiumStaysLodgingsCount(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    availabilityResultsFirstIndexOfHomeResult:
      getFirstIndexOfHomesInAvailabilityLodgingsResults(state),
    hasChangedPolicyFilter: hasChangedHotelAvailabilityPolicyFilter(state),
    hotelAvailabilityCallState: getHotelAvailabilityCallState(state),
    hasChangedHotelAvailabilityStayTypeFilter:
      hasChangedHotelAvailabilityStayTypeFilter(state),
  };
};

const mapDispatchToProps = {
  setAmenitiesFilter,
  setStarRatingsFilter,
  setMaxPriceFilter,
  setHotelNameFilter,
  setFreeCancelFilter,
  setHotelsOnSaleFilter,
  setHotelAvailabilitySortOption,
  setPolicyFilter,
  setLoyaltyProgramsFilter,
  setApplyUserHotelPreferences,
  setPremiumStaysOnly,
  setStayTypeFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityFilter = withRouter(
  connector(AvailabilityFilter)
);
