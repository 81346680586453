import React from "react";
import {
  MobileHotelShopRoomTypePickerPanelRedesign as MobileHotelShopRoomTypePickerPanel,
  MultiroomSuffixText,
} from "halifax";
import { MobileHotelShopRoomTypePickerPanelRedesignConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { HotelShopStep } from "../../reducer/state";
import { CorpRoomInfoProducts } from "redmond/build/capone-corporate";
import { getConfigTenant, isCorpTenant } from "@capone/common";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";
import { config } from "../../../../api/config";

export interface MobileHotelShopRoomTypePickerPanelRedesignProps
  extends MobileHotelShopRoomTypePickerPanelRedesignConnectorProps,
    RouteComponentProps {
  onContinueClicked?: () => void;
}

export const MobileHotelShopRoomTypePickerPanelRedesign = (
  props: MobileHotelShopRoomTypePickerPanelRedesignProps
) => {
  const {
    setHotelShopProgress,
    rewardsKey,
    onContinueClicked,
    roomInfoProducts,
    roomsCount,
    selectedLodging,
  } = props;

  const includeTaxesAndFeesInTotalPrice =
    selectedLodging?.price?.displayTaxesAndFeesIncluded;

  React.useEffect(() => {
    const footer = document.querySelector(
      ".mclean-generic-footer"
    ) as HTMLElement;
    const fixedComponent = document.querySelector(
      ".mobile-hotel-shop-price-panel-redesign"
    ) as HTMLElement;
    const travelWalletDrawerButton = document.querySelector(
      ".travel-wallet-button"
    ) as HTMLElement;
    if (fixedComponent && footer) {
      footer.style.paddingBottom = `${fixedComponent.clientHeight}px`;
    }
    if (fixedComponent && travelWalletDrawerButton) {
      travelWalletDrawerButton.style.bottom = `${
        fixedComponent.clientHeight + 20
      }px`;
    }
    return () => {
      if (footer) {
        footer.style.paddingBottom = "85px";
      }
      if (travelWalletDrawerButton) {
        travelWalletDrawerButton.style.bottom = isCorpTenant(config.TENANT)
          ? "130px"
          : "100px";
      }
    };
  }, [rewardsKey]);
  return (
    <MobileHotelShopRoomTypePickerPanel
      {...props}
      onClickContinue={() => {
        setHotelShopProgress(HotelShopStep.ChooseRoom);
        if (onContinueClicked) {
          onContinueClicked();
        }
      }}
      className="b2b"
      policyComplianceType={getRoomPolicyComplianceType(
        roomInfoProducts as CorpRoomInfoProducts[]
      )}
      tenant={getConfigTenant()}
      showPrice={!includeTaxesAndFeesInTotalPrice}
      suffixText={<MultiroomSuffixText roomsCount={roomsCount} />}
    ></MobileHotelShopRoomTypePickerPanel>
  );
};
