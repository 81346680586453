import { connect, ConnectedProps } from "react-redux";
import {
  getHotelAvailabilityLodgingIdInFocus,
  getHotelAvailabilityLodgings,
  getHotelAvailabilityCallState,
  getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching,
  getAppliedHotelAvailabilityFilterCount,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
  getFirstIndexOfHomesInAvailabilityLodgingsResults,
} from "../../reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { MobileHotelAvailability } from "./component";
import { withRouter } from "react-router-dom";
import { getApplyUserHotelPreferences } from "../../../search/reducer";
import { Dispatch } from "@reduxjs/toolkit";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setMaxPriceFilter,
  setStarRatingsFilter,
} from "../../actions/actions";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";
import { initialFilterState } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const lodgingIdInFocus = getHotelAvailabilityLodgingIdInFocus(state);
  const lodgings = getHotelAvailabilityLodgings(state);
  const hotelAvailabilityCallState = getHotelAvailabilityCallState(state);
  const isFilteredHotelAvailabilityLodgingsEmpty =
    getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching(state);
  return {
    lodgingIdInFocus,
    lodgings,
    hotelAvailabilityCallState,
    isFilteredHotelAvailabilityLodgingsEmpty,
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    appliedFilterCount: getAppliedHotelAvailabilityFilterCount(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    availabilityResultsFirstIndexOfHomeResult:
      getFirstIndexOfHomesInAvailabilityLodgingsResults(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setApplyUserHotelPreferences(false));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelAvailability = withRouter(
  connector(MobileHotelAvailability)
);
